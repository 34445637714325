import React, { Component } from 'react'
import { connect } from "react-redux";
import ReactTable from "react-table";
import { digitСonversion } from "../../../helpers/utility";
import { Button } from "reactstrap";
import salesActions from "../../../redux/sales/actions";

const {
  getProductSales,
  getProductExport,
} = salesActions;

class SalesProductTable extends Component {
  state = {
    page: 1,
    page_size: 10,
  }

  generateTableColumns = () => {
    let tableColumns = [
      {
        accessor: "title",
        Header: "Title",
        headerClassName: "text-left",
        minWidth: 200,
        Cell: props => (
          <div className="d-flex gap-2">
            <img
              src={
                props.original.image_url
                  ? props.original.image_url
                  : `https://www.amazon.com/images/P/${props.original.asin}.01._THUMBZZZ_.jpg`
              }
              alt={props.original.title}
              style={{ maxWidth: 35 }}
            />
            <div>
              <span className="d-block">{props.original.title}</span>
              <span>ASIN: {props.original.asin}</span>
            </div>
          </div>
        )
      },
      {
        accessor: "sku",
        Header: "SKU",
        headerClassName: "text-left",
      },
      {
        accessor: "supplier",
        Header: "Supplier",
        headerClassName: "text-left",
      },
      {
        accessor: "items_sold",
        Header: "Items Sold",
        headerClassName: "text-right",
        className: "text-right",
        Cell: props => digitСonversion(props.original.items_sold)
      },
      {
        accessor: "net_sales",
        Header: "Net Sales",
        headerClassName: "text-right",
        className: "text-right",
        Cell: props => digitСonversion(props.original.net_sales, "currency")
      },
      {
        accessor: "cogs",
        Header: "COGS",
        headerClassName: "text-right",
        className: "text-right",
        Cell: props => digitСonversion(props.original.cogs, "currency")
      },
      {
        accessor: "gross_profit",
        Header: "Gross Profit",
        headerClassName: "text-right",
        className: "text-right",
        Cell: props => digitСonversion(props.original.gross_profit, "currency")
      },
      {
        accessor: "margin",
        Header: "Margin",
        headerClassName: "text-right",
        className: "text-right",
        Cell: props => digitСonversion(props.original.margin, "percent", 2)
      },
    ]
    return tableColumns
  }

  componentDidMount = () => {
    this.props.getProductSales({
      time: this.props.time_period?.value,
    });
  }

  componentDidUpdate = (pp) => {
    if(pp.time_period != this.props.time_period){
      this.props.getProductSales({
        page: 1,
        page_size: this.state.page_size,
        time: this.props.time_period?.value,
      });
      this.setState({
        page: 1,
      })
    }
  }

  render() {
    const {
      product_sales_data,
      product_sales_data_working,
      product_data_export_working,
    } = this.props;

    //console.log("product data:", product_sales_data)

    return (
      <div className="sales-report-product">
        <div className="d-flex gap-3 justify-content-end mb-3">
          <Button
            disabeld={product_data_export_working}
            onClick={() => this.props.getProductExport({
              time: this.props.time_period?.value})
            }
          >Export data</Button>
        </div>
        <ReactTable
          data={product_sales_data?.items ? product_sales_data.items : []}
          pages={product_sales_data?.pages ? product_sales_data.pages : 1}
          columns={this.generateTableColumns()}
          pageSize={this.state.page_size}
          page={this.state.page - 1}
          manual
          loading={product_sales_data_working}
          showPagination={true}
          showPageSizeOptions= {true}
          pageSizeOptions={[10, 20, 50, 100]}
          defaultPageSize={10}
          onPageChange={page => {
            this.setState({page: page + 1})
            this.props.getProductSales({
              page: page + 1,
              page_size: this.state.page_size,
              time: this.props.time_period?.value,
            })
          }}
          onPageSizeChange={(pageSize, page) => {
            this.setState({
              page_size: pageSize,
            })
            this.props.getProductSales({
              page: page + 1,
              page_size: pageSize,
              time: this.props.time_period?.value,
            })
          }}
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    product_sales_data: state.Sales.get("product_sales_data"),
    product_sales_data_working: state.Sales.get("product_sales_data_working"),
    product_data_export_working: state.Sales.get("product_data_export_working"),
  }),
  {
    getProductSales,
    getProductExport,
  }
)(SalesProductTable);
