export default [
    "A_GEN_TAX",
    "A_GEN_NOTAX",
	"A_GEN_STANDARD",
    "A_BOOKS_GEN",
    "A_BOOKS_RELIG",
    "A_NEWS_104PLUS",
    "A_NEWS_52WKLY",
    "A_NEWS_26BIWKLY",
    "A_NEWS_12MTHLY",
    "A_NEWS_4QTLY",
    "A_PERDCL_104PLUS",
    "A_PERDCL_52WKLY",
    "A_PERDCL_52W-4Q",
    "A_PERDCL_SEMIANNL",
    "A_PERDCL_ANNUAL",
    "A_CLSFD_52WKLY",
    "A_CLSFD_52W-4Q",
    "A_CLSFD_SEMIANNL",
    "A_CLSFD_ANNUAL",
    "A_CLTH_GEN",
    "A_CLTH_BATH",
    "A_CLTH_BUCKLS",
    "A_CLTH_COMPON",
    "A_CLTH_ATHL",
    "A_CLTH_CSTUMS",
    "A_CLTH_FORMAL",
    "A_CLTH_FUR",
    "A_CLTH_HANDKE",
    "A_CLTH_HBAGS",
    "A_CLTH_IFUR",
    "A_SPORT_ATHLSHOES",
    "A_SPORT_ASUPPORT",
    "A_SPORT_BIKEHLMT",
    "A_SPORT_MISCSPORTS1",
    "A_SPORT_SKISUIT",
    "A_FOOD_GEN",
    "A_FOOD_CNDY",
    "A_FOOD_CNDYFL",
    "A_FOOD_BKTGN76-90",
    "A_FOOD_BKTGN50-75",
    "A_FOOD_BKTCDY50-90",
    "A_FOOD_JUICE0-50",
    "A_FOOD_JUICE51-99",
    "A_FOOD_CARBSFTDK",
    "A_FOOD_CARBWTR",
    "A_FOOD_NCARBWTR",
    "A_SCHL_SUPPLS",
    "A_COMP_COMPUTER",
    "A_COMP_GAMPER",
    "A_COMP_PDA",
    "A_COMP_PERIPH",
    "A_COMP_PRTSUP",
    "A_COMP_PRINT",
    "A_COMP_EDUSOFT",
    "A_COMP_SOFTOP",
    "A_COMP_SOFTRC",
    "A_WARR_PARTSNSRVC",
    "A_SERV_INSTALL",
    "A_EGOODS_SOFT",
    "A_EGOODS_MISC1",
    "A_EGOODS_DIGITALMUSIC",
    "A_EGOODS_DIGITALBOOKS",
    "A_EGOODS_DIGITALGAMES",
    "A_EGOODS_ONLINEGAMINGSUBS",
    "A_EGOODS_DIGITALNEWS",
    "A_EGOODS_DIGITALNEWSSUBS",
    "A_EGOODS_DIGITALPERDCL",
    "A_EGOODS_DIGITALPERDCLSUBS",
    "A_HLTH_OTCMED",
    "A_HLTH_SPFCOTCMED",
    "A_HLTH_SPFCORALHYG",
    "A_HLTH_CONTACTSOLN",
    "A_HLTH_SUNSCRN",
    "A_HLTH_BANDKIT",
    "A_HLTH_THRMTR",
    "A_HLTH_FEMHYG",
    "A_HLTH_TISSUETOW",
    "A_HLTH_INCONT",
    "A_HLTH_CONTRCEPV",
    "A_HLTH_FAMPLANTEST",
    "A_HLTH_BABYSUPPLS",
    "A_HLTH_DIETSUPMT",
    "A_HLTH_MONITOR",
    "A_HLTH_DIABSUPPLS",
    "A_HLTH_PROSTHETIC",
    "A_HLTH_MOBILITY",
    "A_BABY_BRSTPUMP",
    "A_BABY_CLOTH",
    "A_BABY_CARSEAT"
  ]
