import { all, takeLatest, put, fork, call } from "redux-saga/effects";

import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';

import actions from "./actions";
import appActions from "../app/actions";
import { logError } from "../../helpers/utility";
import { generateInventoryItemsFileExport } from "../../views/pages/inventory/exportCSV";

export function* FetchInventoryItemsRequest() {
  yield takeLatest("FETCH_INVENTORY_ITEMS", function*(payload) {
    try {
		const { channel, status, page, per_page, sort, sort_order,
			search_string } = payload;
			//const inventoryItems = yield call(getInventoryItemsAPI, channel, status, page, per_page, sort, sort_order, search_string);
			const url_params ="/?channel=" + channel + "&status=" + status + "&page_size=" + per_page + "&page=" + page + "&sort=" + sort + "&sort_order=" + sort_order + "&search_string=" + search_string;
			const inventoryItems = yield call(backendAPICall, 'get', apiEndpoints.inventory.inventory_item + url_params);
      if (inventoryItems.status === 200 && inventoryItems.data.data) {
        yield put(actions.fetchInventoryItemsSuccess(inventoryItems.data.data));
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
      logError(error, {
        tags: {
          exceptionType: "FETCH_INVENTORY_ITEMS_ERROR"
        }
      });
    }
  });
}

export function* ChangeInventoryTableFieldData() {
  yield takeLatest("CHANGE_INVENTORY_TABLE_FIELD_DATA", function*(payload) {
    try {
      const { newTableRowData } = payload;
      // should be here trigger for  API function to change inventory table data
      yield put(actions.changeInventoryTableFieldDataSuccess(newTableRowData));
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
      logError(error, {
        tags: {
          exceptionType: "CHANGE_INVENTORY_TABLE_FIELD_DATA_ERROR"
        }
      });
    }
  });
}

export function* UploadInventoryItemsFile() {
  yield takeLatest("UPLOAD_INVENTORY_ITEMS_FILE", function*(payload) {
    try {
      const { inventoryItemsData } = payload;
      var data = new FormData();
      data.append('file', inventoryItemsData.file);
      data.append('overrideOption', "override_always");
      data.append('mapping', inventoryItemsData.mapping)
			//let response = yield call(() => postUploadInventoryItemsFileAPI(data));
			const response = yield call(backendAPICall, 'post', apiEndpoints.inventory.inventory_item_upload, data);
      if(!!response) {
        let jobId = response.data[inventoryItemsData.file.name].upload_id;
        yield put(actions.setUploadInventoryItemsFileJobId(jobId));
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
      logError(error, {
        tags: {
          exceptionType: "UPLOAD_INVENTORY_ITEMS_FILE_ERROR"
        }
      });
    }
  });
}

export function* FetchStrandedItemsRequest() {
  yield takeLatest("FETCH_STRANDED_ITEMS", function*(payload) {
    try {
      const { channel } = payload;
			//const strandedItems = yield call(getStrandedItemsAPI, channel);
			const strandedItems = yield call(backendAPICall, 'get', apiEndpoints.inventory.inventory_item_stranded + '?fulfilledby=' + channel);
      if (strandedItems.status === 200 && strandedItems.data.data) {
        //if data for export
        if(channel === 'all'){
          yield put(actions.fetchStrandedItemsExportSuccess(strandedItems.data.data));
          yield put(actions.fetchStrandedItemsSuccess(strandedItems.data.data));
        } else {
          yield put(actions.fetchStrandedItemsSuccess(strandedItems.data.data));
        }
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
      logError(error, {
        tags: {
          exceptionType: "FETCH_STRANDED_ITEMS_ERROR"
        }
      });
    }
  });
}

export function* getStatsRequest() {
  yield takeLatest("GET_INVENTORY_STATS", function*(payload) {
    const { data } = payload;
    try {
			const stats = yield call(backendAPICall, 'get', apiEndpoints.inventory.inventory_item_stats, data ? data : {});
      if (stats.data) {
			  yield put(actions.getInventoryStatsSuccess(stats.data));
		  } else {
			  yield put(actions.getInventoryStatsFailed());
		  }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
			yield put(actions.getInventoryStatsFailed());
      logError(error, {
        tags: {
          exceptionType: "GET_INVENTORY_STATS_ERROR"
        }
      });
    }
  });
}

export function* updateInventoryRequest() {
	yield takeLatest("UPDATE_INVENTORY", function*(payload) {
    try {
			const response = yield call(backendAPICall, 'post', apiEndpoints.inventory.inventory_item+'/', payload.data);
      if (!response.data.error) {
			  yield put(actions.updateInventorySuccess(response.data.data));
		  } else {
			  yield put(actions.updateInventoryFailed());
		  }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
			yield put(actions.updateInventoryFailed());
      logError(error, {
        tags: {
          exceptionType: "UPDATE_INVENTORY_ERROR"
        }
      });
    }
  });
}

export function* updateInventoryField() {
  yield takeLatest("UPDATE_INVENTORY_FIELD", function*(payload) {
    const { data } = payload;
    yield put(actions.updateInventoryFieldSuccess(data));
  });
}

export function* invantoryDownload() {
	yield takeLatest("INVENTORY_DOWNLOAD", function*(payload) {
    try {
			const response = yield call(backendAPICall, 'post', apiEndpoints.inventory.export, payload.data);
      if (!response.data.error) {
        yield put(actions.invantoryDownloadSuccess(response.data));
		  } else {
			  yield put(actions.invantoryDownloadFailed());
		  }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetching api error export inventory"));
			yield put(actions.invantoryDownloadFailed());
      logError(error, {
        tags: {
          exceptionType: "INVENTORY_DOWNLOAD_ERROR"
        }
      });
    }
  });
}

export function* invantoryDownloadCheck() {
	yield takeLatest("INVENTORY_DOWNLOAD_CHECK", function*(payload) {
    try {
			const response = yield call(backendAPICall, 'get', apiEndpoints.inventory.export, payload.data);
      if (!response.data.error && response?.data?.inventory_items) {
        yield put(actions.invantoryDownloadCheckSuccess(response.data.inventory_items));
        generateInventoryItemsFileExport(response.data.inventory_items, [])
		  }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
			yield put(actions.invantoryDownloadCheckFailed());
			yield put(actions.invantoryDownloadFailed());
      logError(error, {
        tags: {
          exceptionType: "INVENTORY_DOWNLOAD_ERROR"
        }
      });
    }
  });
}

export function* inventoryItemDelete() {
	yield takeLatest("INVENTORY_ITEM_DELETE", function*(payload) {
    try {
			const response = yield call(backendAPICall, 'post', apiEndpoints.inventory.delete, payload.data);
      if (!response.data.error) {
        yield put(actions.inventoryItemDeleteSuccess(response.data));
		  } else {
			  yield put(actions.inventoryItemDeleteFailed());
        yield put(appActions.apiCallFailed(response.data.error));
		  }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
			yield put(actions.inventoryItemDeleteFailed());
      logError(error, {
        tags: {
          exceptionType: "INVENTORY_ITEM_DELETE_ERROR"
        }
      });
    }
  });
}

export function* inventoryItemExpireGet() {
  yield takeLatest("INVENTORY_ITEM_EXPIRE_GET", function*(payload) {
    try {
  		const { channel, status, page, per_page, sort, sort_order,
	  		search_string } = payload.data;
			//const inventoryItems = yield call(getInventoryItemsAPI, channel, status, page, per_page, sort, sort_order, search_string);
			const url_params ="/?channel=" + channel + "&status=" + status + "&page_size=" + per_page + "&page=" + page + "&sort=" + sort + "&sort_order=" + sort_order + "&search_string=" + search_string;
			const inventoryItems = yield call(backendAPICall, 'get', apiEndpoints.inventory.expire + url_params);
      if (inventoryItems.status === 200 && inventoryItems.data.data) {
        yield put(actions.inventoryItemExpireGetSuccess(inventoryItems.data.data));
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
      logError(error, {
        tags: {
          exceptionType: "INVENTORY_ITEM_EXPIRE_GET_ERROR"
        }
      });
    }
  });
}

export function* inventoryItemActivate() {
	yield takeLatest("INVENTORY_ITEM_ACTIVATE", function*(payload) {
    try {
			const response = yield call(backendAPICall, 'put', apiEndpoints.inventory.activate, payload.data);
      if (!response.data.error) {
        yield put(actions.inventoryItemActivateSuccess(response.data));
		  } else {
			  yield put(actions.inventoryItemActivateFailed());
        yield put(appActions.apiCallFailed(response.data.error));
		  }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
			yield put(actions.inventoryItemActivateFailed());
      logError(error, {
        tags: {
          exceptionType: "INVENTORY_ITEM_ACTIVATE_ERROR"
        }
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(FetchInventoryItemsRequest),
    fork(UploadInventoryItemsFile),
    fork(ChangeInventoryTableFieldData),
    fork(FetchStrandedItemsRequest),
		fork(getStatsRequest),
		fork(updateInventoryRequest),
		fork(updateInventoryField),
    fork(invantoryDownload),
    fork(inventoryItemDelete),
    fork(inventoryItemExpireGet),
    fork(inventoryItemActivate),
    fork(invantoryDownloadCheck)
  ]);
}
