import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Papa from 'papaparse'
import {
  Card, CardBody, CardTitle,
	Input, Button, FormGroup, Label,
	Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import inventoryActions from "../../../redux/inventory/actions";
import InventoryTable from "./components/InventoryTable";
import InventoryTableSBYB from "./components/InventoryTableSBYB";
import StrandedTable from "./components/StrandedTable";
import InventoryUploadLoader from "./components/inventoryUploadLoader";
import InventoryUploader from "./components/InventoryUploader";
import InventoryFileMapping from "./components/InventoryFileMapping";
import settingActions from "../../../redux/settings/actions";
import UpdateWarningModal from "./components/UpdateWarningModal";
import InventoryMetrics from "./components/InventoryMetrics";
import ExpireSoonTable from "./components/ExpireSoonTable";

import { getConditionMappingInventoryPrintLabels } from "../../../helpers/inventory/utility";
import { columnMappingForInventoryPrintLabel } from "../../../helpers/inventory/mapping_data";
import { backendHost, request } from "../../../helpers/apiConfig";
import printerActions from "../../../redux/print_service/actions";
import { generateStrandedItemsFileExport } from "./exportCSV";
import authActions from "../../../redux/auth/actions";
import ebayActions from '../../../redux/ebay/actions';
import { checkIfEmployee } from '../../../helpers/utility';
import appActions from "../../../redux/app/actions";

const { userPageviewCapture } = appActions;

const { ebayGetItems, ebayGetUserOrderItems } = ebayActions;

const {
	setUploadInventoryItemsFileJobId,
	fetchInventoryItems,
	fetchStrandedItems,
	uploadInventoryItemsFile,
	changeInventoryTableFieldData,
  getInventoryStats,
  invantoryDownload,
  invantoryDownloadCheck,
  inventoryItemExpireGet,
} = inventoryActions;
const { print } = printerActions;
const { fetchPrinterDefaults, updateUserSettings } = settingActions;
const { updateUserData } = authActions;

const ourHeaderColumns = [
  "SellerSKU" ,"Cost/Unit" ,"Supplier","Date Purchased",
];

const otherHeaderColumns = [
  'Title', 'MSKU' ,'ASIN' ,'FNSKU' ,'On Hand' ,'Total In Stock Buy Cost' ,'Replens', 'List Price', 'Active Cost/Unit' ,'Active Supplier' ,'Active Date Purchased', 'Notes'
]

const default_page_size = 10;

class Inventory extends Component {
  state = {
    selectInventoryValue: "",
    isOpenInventoryUploaderModal: false,
    inventoryChannel: "all",
    inventoryCurrentlyViewingStatus: "active",
    fileHeaders: null,
    file: null,
    openFileMappingModal: false,
    loading: false,
	  inventoryLoaded: false,
	  strandedLoaded: false,
	  activeTable: 'inventory',
	  showExportStranded: false,
		searchText: "",
		display_stranded: [],
		display_inventory: {},
		searchPlaceholder: "Search title / ASIN / SKU...",
		showUpdateWarning: false,
		activeTab: "1",
		sort: 'quantity',
		sort_order: 'desc',
  };

  handleSearchChange(event) {
  	this.handleSearch(event.target.value);
	  this.setState({searchText: event.target.value});
  }

  handleExportStranded = () => {
	  generateStrandedItemsFileExport(this.props.strandedItemsExport, []);
  };

  handleExportInventory = () => {
    this.props.invantoryDownload();
		const intervalID = setInterval(
	      () => this.invantoryDownloadCheck(),
  		  3000
    );
    this.setState({ intervalID: intervalID })
  };

  invantoryDownloadCheck = () => {
    this.props.invantoryDownloadCheck({job: this.props.export_inventory_job})
  }

	componentWillUnmount() {
		if(this.state.intervalID){
			clearInterval(this.state.intervalID);
		}
	}

  handleSearch(search){
		if(['3'].includes(this.state.activeTab)){
			if(this.state.activeTab === '3'){
        this.fetchInventoryExpireData(1, 10, null, search)
			}
		} else {
			if(this.state.inventoryCurrentlyViewingStatus === 'stranded_closed'
				|| this.state.inventoryCurrentlyViewingStatus === 'stranded_active'){
				this.searchStranded(search);
			} else {
				this.searchInventory(search);
			}
		}
  }

	searchEbay(search){
		const data = {
			page: 1,
			page_size: 10,
			sort: 'id',
			sort_order: 'desc',
			search_text: search,
		}
		this.props.ebayGetItems(data);
  }

	searchEbayOrders(search){
		const data = {
			page: 1,
			page_size: 10,
			sort: 'id',
			sort_order: 'desc',
			search_text: search,
		}
		this.props.ebayGetUserOrderItems(data);
  }

  searchInventory(search){
    const { inventoryChannel, inventoryCurrentlyViewingStatus } = this.state;
		this.props.fetchInventoryItems(
			inventoryChannel,
			inventoryCurrentlyViewingStatus,
			1,
			default_page_size,
			this.state.sort,
			this.state.sort_order,
			search,
		);
		this.setState({
			activeTable: 'inventory',
			inventoryLoaded: true,
		});
		this.setSearchPlaceholder('inventory');
		this.setState({display_inventory: this.props.inventoryItems});
  }

  searchStranded(search){
    if(!search){
      this.setState({display_stranded: this.props.strandedItems});
      return;
    }
	  let stranded_to_display = [];
	  search = search.toUpperCase();
	  this.props.strandedItems.forEach((row) => {
      let key = [row.asin, row.sku, row.fnsku]
      key = key.join(' ').toUpperCase();
      if(key.includes(search)){
        stranded_to_display.push(row);
      }
	  });
	  this.setState({display_stranded: stranded_to_display});
  }

  fetchInventoryExpireData = (page, per_page, sorted, search) => {
		const {
			inventoryChannel,
			inventoryCurrentlyViewingStatus,
			searchText,
		} = this.state;
    this.setState({inventoryLoaded: true});
		let sort = this.state.sort;
		let sort_order = this.state.sort_order;
    if(sorted?.length > 0) {
      sort = sorted[0].id;
      sort_order = sorted[0].desc ? 'desc' : 'asc';
    }
    this.props.inventoryItemExpireGet({
      channel: inventoryChannel,
      status: inventoryCurrentlyViewingStatus,
      page,
      per_page,
      sort,
			sort_order,
			search_string: search ? search : searchText,
    });
  }

  fetchInventoryData = (page, per_page, sorted) => {
		const {
			inventoryChannel,
			inventoryCurrentlyViewingStatus,
			searchText,
		} = this.state;
    this.setState({inventoryLoaded: true});
		let sort = this.state.sort;
		let sort_order = this.state.sort_order;
    if(sorted.length > 0) {
      sort = sorted[0].id;
      sort_order = sorted[0].desc ? 'desc' : 'asc';
    }
    this.props.fetchInventoryItems(
      inventoryChannel,
      inventoryCurrentlyViewingStatus,
      page,
      per_page,
      sort,
			sort_order,
			searchText,
    );
    this.props.fetchPrinterDefaults();
  }

  updateLoadingFlag = (flag) => {
    this.setState({loading: flag});
  }

  setSearchPlaceholder(state){
		if(state === 'stranded'){
			this.setState({searchPlaceholder: 'SEARCH ASIN / SKU / FNSKU...'});
		} else {
			this.setState({searchPlaceholder: 'SEARCH TITLE / ASIN / SKU...'});
		}
  }

  handleChangeInventoryChannelFilter = e => {
    const inventoryChannel = e.target.value;
		const {
			inventoryCurrentlyViewingStatus,
			sort,
			sort_order,
		} = this.state;
    this.setState({
      inventoryChannel: inventoryChannel
    });
		if(inventoryCurrentlyViewingStatus === 'stranded_closed' ||
				inventoryCurrentlyViewingStatus === 'stranded_active'){
			this.setState({showExportStranded: true});
			this.props.fetchStrandedItems(inventoryChannel);
			this.setState({
				activeTable: 'stranded',
				strandedLoaded: true,
			});
			this.setSearchPlaceholder('stranded');
		} else {
			this.setState({showExportStranded: false});
			this.props.fetchInventoryItems(
				inventoryChannel,
				inventoryCurrentlyViewingStatus,
				1,
				default_page_size,
				sort,
				sort_order,
				this.state.searchText,
			);
			this.setState({
				activeTable: 'inventory',
				inventoryLoaded: true,
			});
			this.setSearchPlaceholder('inventory');
		}
  };

  handleChangeInventoryViewingStatusFilter = e => {
    const inventoryCurrentlyViewingStatus = e.target.value;
		const {
			inventoryChannel,
			sort,
			sort_order
		} = this.state;
    this.setState({
      inventoryCurrentlyViewingStatus: inventoryCurrentlyViewingStatus,
    });
		if(inventoryCurrentlyViewingStatus === 'stranded_closed' ||
				inventoryCurrentlyViewingStatus === 'stranded_active'){
			this.setState({
				showExportStranded: true,
				searchText: "",
			});
			this.props.fetchStrandedItems(inventoryChannel);
			this.setState({
				activeTable: 'stranded',
				strandedLoaded: true,
			});
			this.setSearchPlaceholder('stranded');
		} else {
			this.setState({showExportStranded: false});
			this.props.fetchInventoryItems(
				inventoryChannel,
				inventoryCurrentlyViewingStatus,
				1,
				default_page_size,
				sort,
				sort_order,
				this.state.searchText,
			);
			this.setState({
				activeTable: 'inventory',
				inventoryLoaded: true,
			});
			this.setSearchPlaceholder('inventory');
		}
  };

  closeUploadModal = () => {
    this.setState({
      loading: false
    });
  };

  closeInventoryFileMappingModal = () => {
    this.setState({
      openFileMappingModal: false
    });
  };

  closeInventoryUploaderModal = () => {
    this.setState({
      isOpenInventoryUploaderModal: false
    });
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    let jobId = newProps.uploadJobId;
    if(!!jobId) {
      this.pollUploadProcessingStatus(jobId);
    }
    if(!!newProps.inventoryItems && this.props.inventoryItems !== newProps.inventoryItems) {
      this.setState({inventoryLoaded: false});
	  this.setState({display_inventory: newProps.inventoryItems});
    }
    if(!!newProps.strandedItems && this.props.strandedItems !== newProps.strandedItems) {
      this.setState({strandedLoaded: false});
	  this.setState({display_stranded: newProps.strandedItems});
    }
    if(!this.props.strandedItems){
      this.props.fetchStrandedItems(this.state.inventoryChannel);
    }
    if(newProps.inventory_export_data_loading === false){
			clearInterval(this.state?.intervalID);
    }
  }

	closeShowUpdateWarning = () => {
		let data = {inventory_warning: "true"};
		this.props.updateUserSettings(data);
		this.setState({ showUpdateWarning: false});
	}

	componentDidMount(){
		const { userData } = this.props;
    this.props.userPageviewCapture({url: window.location.href, al_page: "Inventory"})
		//get data for export
		this.props.fetchStrandedItems('all');
		if(userData){
			if(!userData.settings["inventory_warning"]){
				let ud = userData;
				ud.settings["inventory_warning"] = "true";
				this.props.updateUserData(ud);
				this.setState({ showUpdateWarning: true});
			}
		}
		this.props.getInventoryStats();
	}

  handleClickUploadButton = () => {
    this.setState({
      isOpenInventoryUploaderModal: true
    });
  };

  printLabel = (listing) => {
    const { print } = this.props;
    let printlabelListing = {};

    Object.keys(listing).forEach(el => {
      const mappingKey = Object.keys(columnMappingForInventoryPrintLabel).indexOf(el);
      if (mappingKey !== -1) {
        const mappingFieldName = columnMappingForInventoryPrintLabel[el];
        if (mappingFieldName === "condition") {
          printlabelListing[mappingFieldName] = getConditionMappingInventoryPrintLabels(listing[el]);
        } else {
          printlabelListing[mappingFieldName] = listing[el];
        }
      }
	});

    print(printlabelListing, listing.quantity || 1);
  }

  isOurHeader = (headers) => {
    let check = false;
    if(headers.indexOf('SellerSKU') > -1 && headers.indexOf('Cost/Unit') > -1) {
      check = true;
      headers.forEach((header) => {
        if(ourHeaderColumns.indexOf(header) < 0) {
          check = false;
        }
      })
    }
    return check;
  };

  isPartnerHeader = (headers) => {
    let check = false;
    if(headers.indexOf('MSKU') > -1 && headers.indexOf('Active Cost/Unit') > -1) {
      check = true;
      headers.forEach((header) => {
        if(otherHeaderColumns.indexOf(header) < 0) {
          check = false;
        }
      })
    }
    return check;
  };

  mapHeaderToLabelAndValue = (headers) => {
    let optionsArray = [];
    headers.forEach((header) => {
      optionsArray.push({ label: header, value: header});
    })
    return optionsArray;
  }

  validateParsedFile = (results) => {
    let header = results.data[0].toString();
    let headers = header.split(",");
    if(this.isOurHeader(headers)) {
      let mapping = {
        "seller_sku": 'SellerSKU',
        "buy_cost": 'Cost/Unit',
        "supplier": 'Supplier',
        "date_purchased": 'Date Purchased'
      };
      let data = {
        mapping: JSON.stringify(mapping),
        file: this.state.file
      };
      this.props.uploadInventoryItemsFile(data);
      this.closeInventoryUploaderModal();
      this.setState({loading: true});
    }
    else if (this.isPartnerHeader(headers))
    {
      let mapping = {
        "seller_sku": 'MSKU',
        "buy_cost": 'Active Cost/Unit',
        "supplier": 'Active Supplier',
        "date_purchased": 'Active Date Purchased'
      }
      let data = {
        mapping: JSON.stringify(mapping),
        file: this.state.file
      }
      this.props.uploadInventoryItemsFile(data);
      this.closeInventoryUploaderModal();
      this.setState({loading: true});
    }
    else {
      let options = this.mapHeaderToLabelAndValue(headers);
      this.setState({ fileHeaders: options, openFileMappingModal: true });
      this.closeInventoryUploaderModal();
    }
  };

  validateUploadedFile = (file) => {
    this.setState({file: file});
    Papa.parse(file, {
      complete: this.validateParsedFile
    });
  };

  pollUploadProcessingStatus = (jobId) => {
    let self = this;
    let {setUploadInventoryItemsFileJobId} = this.props;
    request
      .get(
        backendHost +
        "/api/v1/inventory_item/upload/" +
        jobId
      )
      .then(res => {
        let response = res.body;

        if (response.status !== "failed" && response.status !== "processed") {
          setTimeout(function () {
            self.pollUploadProcessingStatus(jobId);
          }, 1000);
        } else {
          if (response.status === "processed") {
            self.setState({loading: false});
            setUploadInventoryItemsFileJobId(null);
            self.setState({inventoryLoaded: true});
          }
          if (response.status === "failed") {
            self.setState({loading: false});
          }
        }
      })
      .catch(err => {
        console.error("Upload invontory items ERROR", err);
      });
	}

	toggleTab = (tabId) => {
		this.setState({
			sort: 'seller_sku',
			sort_order: 'asc',
		})
		if(tabId === '2'){
			this.setState({
				sort: 'open_date',
				sort_order: 'asc',
			})
		}
		this.setState({
			activeTab: tabId
		})
	}

	render() {
    const {
      uploadInventoryItemsFile,
      changeInventoryTableFieldData
    } = this.props;
	  const {
		  isOpenInventoryUploaderModal,
		  inventoryChannel,
		  openFileMappingModal,
		  fileHeaders,
		  file,
		  loading,
		  inventoryLoaded,
		  showUpdateWarning,
			showExportStranded,
			activeTab
	  } = this.state;
    return (
      <div className="view">
				<header className="view-header d-flex justify-content-between align-items-center">
					<h1 className="page-title">Inventory</h1>
					<div>
						{/* <Button color="secondary"><span className="icon icon-print"></span> Printer Settings</Button> */}
						{showExportStranded && <Button color="info" className="ml-2" onClick={this.handleExportStranded}><span className="icon icon-csv"></span> Export Stranded</Button>}
						<Button
							className="ml-2"
							onClick={e => this.handleExportInventory()}
							disabled={this.props.inventory_export_data_loading}
						>
							<span className="icon icon-csv"></span> Export Inventory
						</Button>
						<Button
							color="primary"
							className="ml-2"
							onClick={this.handleClickUploadButton}
						><span className="icon icon-upload bg-white"></span> Upload</Button>
					</div>
				</header>
				<div className="view-content view-components">
					<Card>
						<CardBody>
							{
								openFileMappingModal &&
								<Fragment>
									<CardTitle>
										<strong>Select the corresponding columns from your CSV</strong>
									</CardTitle>
									<InventoryFileMapping
										close={this.closeInventoryFileMappingModal}
										options={fileHeaders}
										file={file}
										uploadInventoryItemsFile={uploadInventoryItemsFile}
										updateLoadingFlag={this.updateLoadingFlag}
									/>
								</Fragment>
							}
							{
								isOpenInventoryUploaderModal &&
								<Fragment>
									<CardTitle className="text-center">
										Upload Inventory MSKU Buy Costs/Supplier
									</CardTitle>
									<InventoryUploader
										isOpen={isOpenInventoryUploaderModal}
										close={this.closeInventoryUploaderModal}
										uploadInventoryItemsFile={this.validateUploadedFile}
									/>
								</Fragment>
							}
							{
								loading &&
								<InventoryUploadLoader
									close={this.closeUploadModal}
								/>
							}
							{
								!isOpenInventoryUploaderModal &&  !loading && !openFileMappingModal &&
								<Fragment>
								<div className="d-flex justify-content-between align-items-center mb-4">
									<div className="d-flex">
										{['1', '2', '3'].includes(activeTab) ?
										<React.Fragment>
											<FormGroup>
												<Label>Inventory</Label>
												<Input
													type="select"
													onChange={this.handleChangeInventoryChannelFilter}
													value={inventoryChannel}
												>
													<option value="all">All</option>
													<option value="mf">MF</option>
													<option value="fba">FBA</option>
												</Input>
											</FormGroup>
											<FormGroup className="ml-3">
												<Label>Currently viewing items</Label>
												<Input
													type="select"
													onChange={this.handleChangeInventoryViewingStatusFilter}
													value={this.state.inventoryCurrentlyViewingStatus}
												>
													<option value="all">All</option>
													<option value="active">Active</option>
													<option value="inactive">Inactive</option>
													<option value="closed">Closed</option>
													<option value="stranded_active">Stranded Active</option>
													<option value="stranded_closed">Stranded Inactive</option>
													<option value="not_ebay_listed">Not Listed on Ebay</option>
												</Input>
											</FormGroup>
										</React.Fragment> : null
										}
									</div>
									{this.props.inventoryStats ?
										<div>
											<InventoryMetrics
												inventoryStats={this.props.inventoryStats}
												internationalization_config={this.props.internationalization_config}
												is_ebay_user={this.props.userData && this.props.userData.is_ebay_enabled}
											/>
										</div>
										: null
									}
								</div>
						{this.state.activeTable === "inventory" ?  (
							<React.Fragment>
								<Nav tabs>
									<NavItem>
										<NavLink
											className={activeTab === "1" ? "active" : ""}
											onClick={() => this.toggleTab("1")}
										>
											Inventory
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={activeTab === "2" ? "active" : ""}
											onClick={() => this.toggleTab("2")}
										>
											LTS (Long Term Storage)
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={activeTab === "3" ? "active" : ""}
											onClick={() => this.toggleTab("3")}
										>
											Expiring Soon
										</NavLink>
									</NavItem>
									<NavItem className="ml-auto">
										<Input
											value={this.state.searchText}
                      onChange={e => this.handleSearchChange(e)}
											autoComplete="off"
											type="text"
											placeholder={this.state.searchPlaceholder}
											id="inventory-search"
											style={{ width: "25rem" }}
										/>
									</NavItem>
								</Nav>
								<TabContent activeTab={activeTab} className="px-1 py-3">
									<TabPane tabId="1">
										{activeTab === "1" &&
											<InventoryTable
												inventoryItems={this.state.display_inventory}
												fetchInventoryData={this.fetchInventoryData}
												changeInventoryTableFieldData={changeInventoryTableFieldData}
												showLabelsColumn={inventoryChannel === "fba" ? true : false}
												loading={inventoryLoaded}
												print={this.printLabel}
											/>
										}
									</TabPane>
									<TabPane tabId="2">
										{activeTab === "2" &&
											<InventoryTableSBYB
												inventoryItems={this.state.display_inventory}
												fetchInventoryData={this.fetchInventoryData}
												loading={inventoryLoaded}
											/>
										}
									</TabPane>
									<TabPane tabId="3">
										{activeTab === "3" &&
											<ExpireSoonTable
												fetchInventoryData={this.fetchInventoryExpireData}
												changeInventoryTableFieldData={changeInventoryTableFieldData}
												showLabelsColumn={inventoryChannel === "fba" ? true : false}
												loading={this.props.expire_loading}
												print={this.printLabel}
                        searchText={this.state.searchText}
											/>
										}
									</TabPane>
								</TabContent>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Nav tabs>
									<NavItem className="ml-auto">
										<Input
											value={this.state.searchText}
                      onChange={e => this.handleSearchChange(e)}
											autoComplete="off"
											type="text"
											placeholder={this.state.searchPlaceholder}
											id="inventory-search"
											style={{ width: "25rem" }}
										/>
									</NavItem>
								</Nav>
                <StrandedTable
                  strandedItems={this.state.display_stranded}
                  viewStatus={this.state.inventoryCurrentlyViewingStatus}
                />
							</React.Fragment>
						)
						}
									</Fragment>
							}
					</CardBody>
				</Card>
			</div>
			<UpdateWarningModal
				isOpen={showUpdateWarning}
				close={this.closeShowUpdateWarning}
			/>
      </div>
    );
  }
}

Inventory.propTypes = {
  fetchInventoryItems: PropTypes.func.isRequired,
  uploadInventoryItemsFile: PropTypes.func.isRequired,
  inventoryItems: PropTypes.object,
  changeInventoryTableFieldData: PropTypes.func.isRequired,
  setUploadInventoryItemsFileJobId: PropTypes.func.isRequired
};

export default connect(
  state => ({
    inventoryItems: state.Inventory.get("inventoryItems"),
    strandedItems: state.Inventory.get("strandedItems"),
    strandedItemsExport: state.Inventory.get("strandedItemsExport"),
    uploadJobId: state.Inventory.get("uploadJobId"),
    userData: state.Auth.get("userData"),
		inventoryStats: state.Inventory.get("inventoryStats"),
		inventory_export_data_loading: state.Inventory.get("inventory_export_data_loading"),
    internationalization_config: state.Auth.get("internationalization_config"),
		expire_loading: state.Inventory.get("expire_loading"),
		export_inventory_job: state.Inventory.get("export_inventory_job"),
  }),
  {
    setUploadInventoryItemsFileJobId,
    fetchInventoryItems,
    uploadInventoryItemsFile,
    changeInventoryTableFieldData,
    fetchPrinterDefaults,
    print,
	  fetchStrandedItems,
	  updateUserData,
		updateUserSettings,
		getInventoryStats,
		ebayGetItems,
		ebayGetUserOrderItems,
    invantoryDownload,
    invantoryDownloadCheck,
    inventoryItemExpireGet,
    userPageviewCapture,
  }
)(Inventory);
