import React, { Component } from 'react';
import { connect } from "react-redux";
import { Badge, Button } from "reactstrap";
import ReactTable from "react-table";
import orderActions from "../../../redux/order/actions";
import moment from "moment";
import { digitСonversion } from "../../../helpers/utility";
import appActions from "../../../redux/app/actions";
import salesActions from "../../../redux/sales/actions";

const {
  getSummary,
  getSummaryExport,
} = salesActions;

class SummaryTable extends Component {
  state = {
    page: 1,
    page_size: 10,
  }

  generateTableColumns = () => {
    let tableColumns = [
      {
        accessor: "amazon_order_id",
        Header: "Order ID",
        headerClassName: "text-left",
      },
      {
        accessor: "fulfillment_channel",
        Header: "Channel",
        headerClassName: "text-left",
        maxWidth: 100,
      },
      {
        accessor: "purchase_date",
        Header: "Order Date",
        headerClassName: "text-left",
        Cell: props => <span>{moment(props.original.purchase_date).format("LL")}</span>
      },
      {
        accessor: "last_updated_date",
        Header: "Last Update",
        headerClassName: "text-left",
        Cell: props => <span>{moment(props.original.last_updated_date).format("LL")}</span>
      },
      {
        accessor: "order_status",
        Header: "Status",
        headerClassName: "text-left",
        Cell: props => <Badge color="info">{props.original.order_status.toUpperCase()}</Badge>,
        maxWidth: 100,
      },
      {
        accessor: "quantity",
        Header: "Shipped",
        headerClassName: "text-left",
        maxWidth: 70,
      },
      {
        accessor: "item_price",
        Header: "Item Price",
        headerClassName: "text-left",
        Cell: props => <span>{digitСonversion(props.original.item_price, "currency")}</span>
      },
    ]
    return tableColumns
  }

  componentDidMount = () => {
    this.props.getSummary({
      time: this.props.time_period?.value,
    });
  }

  componentDidUpdate = (pp) => {
    if(pp.time_period != this.props.time_period){
      this.props.getSummary({
        page: 1,
        page_size: this.state.page_size,
        time: this.props.time_period?.value,
      });
      this.setState({
        page: 1,
      })
    }
  }

  render() {
    const {
      summary_data,
      summary_data_working,
      summary_data_export_working,
    } = this.props;

    return (
      <div className="sales-report-summary">
        <div className="d-flex gap-3 justify-content-end mb-3">
          <Button
            disabeld={summary_data_export_working}
            onClick={() => this.props.getSummaryExport({
              time: this.props.time_period?.value})
            }
          >Export data</Button>
        </div>
        <ReactTable
          data={summary_data?.items ? summary_data.items : []}
          pages={summary_data?.pages ? summary_data.pages : 1}
          columns={this.generateTableColumns()}
          pageSize={this.state.page_size}
          page={this.state.page - 1}
          manual
          loading={summary_data_working}
          showPagination={true}
          showPageSizeOptions= {true}
          pageSizeOptions={[10, 20, 50, 100]}
          defaultPageSize={10}
          onPageChange={page => {
            this.setState({page: page + 1})
            this.props.getSummary({
              page: page + 1,
              page_size: this.state.page_size,
              time: this.props.time_period?.value,
            })
          }}
          onPageSizeChange={(pageSize, page) => {
            this.setState({
              page_size: pageSize,
            })
            this.props.getSummary({
              page: page + 1,
              page_size: pageSize,
              time: this.props.time_period?.value,
            })
          }}
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    summary_data: state.Sales.get("summary_data"),
    summary_data_working: state.Sales.get("summary_data_working"),
    summary_data_export_working: state.Sales.get("summary_data_export_working"),
  }),
  {
    getSummary,
    getSummaryExport,
  }
)(SummaryTable);
