import actions from "./actions";
import { Map } from "immutable/dist/immutable";

const selectOptions = [
	{ value: 'NewItem', label: 'New Item' },
	{ value: 'UsedGood', label: 'Used - G' },
	{ value: 'UsedVeryGood', label: 'Used - VG' },
	{ value: 'UsedAcceptable', label: 'Used - A' },
	{ value: 'UsedLikeNew', label: 'Used - LN' },
	{ value: 'CollectibleGood', label: 'Collectible - G' },
	{ value: 'CollectibleLikeNew', label: 'Collectible - LN' },
	{ value: 'CollectibleAcceptable', label: 'Collectible - A' },
	{ value: 'CollectibleVeryGood', label: 'Collectible - VG' },
	{ value: 'Refurbished', label: 'Refurbished' },
];

const initState = new Map({
	research_data: [],
	is_loading_research_data: false,
	research_batch_data: [],
	is_moving_to_batch: false,
	is_deleting_research: false,
	is_loading_manual_search: false,
	is_showing_selection_items: false,
	search_results: [],
	moveToBatchData: [],
	research_progress: {
		total: 0,
		processed: 0,
	},
  sku_report: null,
  sku_report_working: null,
  sku_report_history: null,
  sku_report_history_working: null,
});

export default function researchReducer(state = initState, action) {
	switch (action.type) {

	  case actions.RESEARCH_GET_SKU_REPORT_HISTORY:
			return state
				.set("sku_report_history", null)
				.set("sku_report_history_working", true)
	  case actions.RESEARCH_GET_SKU_REPORT_HISTORY_SUCCESS:
			return state
				.set("sku_report_history", [...action.data.data])
				.set("sku_report_history_working", false)
	  case actions.RESEARCH_GET_SKU_REPORT_HISTORY_ERROR:
			return state
				.set("sku_report_history", null)
				.set("sku_report_history_working", false)

	  case actions.RESEARCH_GET_SKU_REPORT:
			return state
				.set("sku_report", null)
				.set("sku_report_working", true)
	  case actions.RESEARCH_GET_SKU_REPORT_SUCCESS:
			return state
				.set("sku_report", {...action.data.data})
        .set("sku_report_history",
          action.data?.data?.history
            ? [...action.data?.data?.history]
            : state.get("sku_report_history")
        )
				.set("sku_report_working", false)
	  case actions.RESEARCH_GET_SKU_REPORT_ERROR:
			return state
				.set("sku_report", null)
				.set("sku_report_working", false)

	  case actions.RESEARCH_GET_STATUS_SUCCESS:
			return state
				.set("research_progress", Object.assign({}, action.data.research_stats))

	  case actions.RESEARCH_DATA_IMPORT:
			return state
				.set("is_loading_research_data", true)
	  case actions.RESEARCH_DATA_IMPORT_SUCCESS:
			let rdata = Object.assign([], state.get("research_data"));
			let r_progress_data = Object.assign({}, state.get("research_progress"));
			let last = rdata.length;
			action.data.data.data.forEach(item => {
				let data = {
					id: ++last,
					img_url: "",
					title: null,
					category: "",
					rank: "",
					offers: 0,
					used_bb: 0,
					new_bb: 0,
					prep_type: "",
					mf_fees: 0,
					fba_fees: 0,
					est_profit: 0,
					sku: item['sku'],
					asin: item['asin'],
					price: item['price'],
					qty: item['qty'],
					processed: false,
					buy_cost: 0,
				}
				rdata.unshift(data);
				r_progress_data.total = r_progress_data.total + 1;
			});
			return state
				.set("research_data", Object.assign([], rdata))
				.set("is_loading_research_data", false)
				.set("research_progress", Object.assign({}, r_progress_data))
	  case actions.RESEARCH_DATA_IMPORT_FAILED:
			return state
				.set("is_loading_research_data", false)

	  case actions.RESEARCH_DATA_LOAD:
			return state
				.set("research_data", [])
				.set("research_progress", Object.assign({}, {total: 0, processed: 0}))
	  case actions.RESEARCH_DATA_LOAD_SUCCESS:
			let research_data = [];
			let data = {}
			let est_profit = 0;
			action.data.data.forEach((item, i) => {
				est_profit = 0;
				data = {
					id: item['id'],
					img_url: item.img_url ? item.img_url : null,
					title: item.title ? item.title: null,
					category: item.category ? item.category: null,
					rank: item.rank ? item.rank : null,
					offers: item.offers ? item.offers : null,
					used_bb: item.used_bb ? item.used_bb : null,
					new_bb: item.new_bb ? item.new_bb : null,
					prep_type: "",
					fba_fees: item.fba_fees ? parseFloat(item.fba_fees) : 0,
					mf_fees: item.mf_fees ? parseFloat(item.mf_fees) : 0,
					sku: item['sku'],
					asin: item['asin'],
					processed: item['processed'],
					price: item['price'],
					buy_cost: item['buyCost'],
					pricing_type_to_display: item["pricing_type_to_display"],
					condition: item['condition'],
					sales: item.sales_count ? item.sales_count : null,
					sbyb: item.sbyb_price ? item.sbyb_price : null,
					sbyb_url: item.sbyb_url ? item.sbyb_url : null,
				}

        let bb = data.used_bb;
        if(data.condition === 'New'){
          bb = data.new_bb;
        }

				if(data.pricing_type_to_display === 'MF'){
					data.fba_fees = null;
					if(bb && item.fba_fees){
						est_profit = bb - data.mf_fees - data.buy_cost;
					}
				} else {
					if(data.pricing_type_to_display === 'FBA'){
						data.mf_fees = null;
					}
					if(bb && item.fba_fees){
						est_profit = bb - data.fba_fees - data.buy_cost;
					}
				}

				if(est_profit){
					data['est_profit'] = est_profit.toFixed(2)
				}
				research_data.unshift(data);
			});
			return state
				.set("research_data", Object.assign([], research_data))
				.set("research_progress", Object.assign({}, action.data.progress))
	  case actions.RESEARCH_DATA_LOAD_FAILED:
			return state
				.set("research_data", [])
				.set("research_progress", Object.assign({}, {total: 0, processed: 0}))

	  case actions.RESEARCH_LOAD_BATCH_DATA:
			return state
				.set("research_batch_data", [])
	  case actions.RESEARCH_LOAD_BATCH_DATA_SUCCESS:
			let research_batch_data = [];
			action.data.data.forEach(item => {
				research_batch_data.push({
					value: item.batch_id,
					label: item.batch_name
				})
			});
			return state
				.set("research_batch_data", Object.assign([], research_batch_data))
	  case actions.RESEARCH_LOAD_BATCH_DATA_FAILED:
			return state
				.set("research_batch_data", [])

	  case actions.RESEARCH_MOVE_TO_BATCH:
			return state
				.set("is_moving_to_batch", true)
	  case actions.RESEARCH_MOVE_TO_BATCH_SUCCESS:
			const rdata_move = Object.assign([], state.get("research_data"));
			const ids_data = Object.assign([], action.data.data.ids);
			const rdata_move_updated = rdata_move.filter(
				item => ids_data.includes(item.id) === false);
			return state
				.set("research_data", Object.assign([], rdata_move_updated))
				.set("is_moving_to_batch", false)
	  case actions.RESEARCH_MOVE_TO_BATCH_FAILED:
			return state
				.set("is_moving_to_batch", false)

	  case actions.RESEARCH_DELETE_SELECTED:
			return state
				.set("is_deleting_research", true)
	  case actions.RESEARCH_DELETE_SELECTED_SUCCESS:
			const rdata_delete = Object.assign([], state.get("research_data"));
			let r_progress_data_delete = Object.assign({}, state.get("research_progress"));
			const ids = Object.assign([], action.data.data);
			const rdata_delete_updated = rdata_delete.filter(
				item => ids.includes(item.id) === false);
			r_progress_data_delete.total = r_progress_data_delete.total - rdata_delete_updated.length;
			return state
				.set("is_deleting_research", false)
				.set("research_data", Object.assign([], rdata_delete_updated))
				.set("research_progress", Object.assign({}, rdata_delete_updated))
	  case actions.RESEARCH_DELETE_SELECTED_FAILED:
			return state
				.set("is_deleting_research", false)

	  case actions.RESEARCH_MANUAL_SEARCH:
			return state
				.set('is_loading_manual_search', true)
				.set('is_showing_selection_items', false)
				.set('search_results', [])
				.set("research_progress", Object.assign({}, {total: 0, processed: 0}))
	  case actions.RESEARCH_MANUAL_SEARCH_FAILED:
			return state
				.set('is_loading_manual_search', false)
				.set('is_showing_selection_items', false)
				.set('search_results', [])
				.set("research_progress", Object.assign({}, {total: 0, processed: 0}))
		case actions.RESEARCH_MANUAL_SEARCH_SUCCESS:
			return state
				.set('is_loading_manual_search', false)
				.set('is_showing_selection_items', true)
				.set('search_results', Object.assign([], action.data.research_items_data))
				.set("research_progress", Object.assign({}, action.data.progress))

		case actions.RESEARCH_MANUAL_SEARCH_RESULTS_DISPLAY_CLOSE:
			return state
				.set('is_showing_selection_items', false)

	  case actions.RESEARCH_MANUAL_SEARCH_SELECT:
			return state
				.set('is_loading_manual_search', true)
				.set('is_showing_selection_items', false)
				.set('search_results', [])
	  case actions.RESEARCH_MANUAL_SEARCH_SELECT_FAILED:
			return state
				.set('is_loading_manual_search', false)
				.set('is_showing_selection_items', false)
				.set('search_results', [])
		case actions.RESEARCH_MANUAL_SEARCH_SELECT_SUCCESS:
			let manual_research_data = Object.assign([], state.get("research_data"));
			let manual_data = {}
			let manual_est_profit = 0;
			var item = Object.assign({}, action.data.research_item_data);
			item = Object.assign(item, item.research_json);
			manual_est_profit = 0;
			manual_data = {
				id: item['id'],
				img_url: item.img_url ? item.img_url : null,
				title: item.title ? item.title: null,
				category: item.category ? item.category: null,
				rank: item.rank ? item.rank : null,
				offers: item.offers ? item.offers : null,
				used_bb: item.used_bb ? item.used_bb : null,
				new_bb: item.new_bb ? item.new_bb : null,
				prep_type: "",
				fba_fees: item.fba_fees ? parseFloat(item.fba_fees) : 0,
				mf_fees: item.mf_fees ? parseFloat(item.mf_fees) : 0,
				sku: item['sku'],
				asin: item['asin'],
				processed: item['processed'],
				price: item['price'],
				buy_cost: item['buyCost'],
				pricing_type_to_display: item["pricing_type_to_display"],
				condition: item['condition'],
				sales: item.sales_count ? item.sales_count : null,
				sbyb: item.sbyb_price ? item.sbyb_price : null,
				sbyb_url: item.sbyb_url ? item.sbyb_url : null,
			}

      let manual_bb = manual_data.used_bb;
      if(manual_data.condition === 'New'){
        manual_bb = manual_data.new_bb;
      }

			if(manual_data.pricing_type_to_display === 'MF'){
				manual_data.fba_fees = null;
				if(manual_bb && item.fba_fees){
					manual_est_profit = manual_bb - manual_data.mf_fees - manual_data.buy_cost;
				}
			} else {
				if(manual_data.pricing_type_to_display === 'FBA'){
					manual_data.mf_fees = null;
				}
				if(manual_bb && item.fba_fees){
					manual_est_profit = parseFloat(manual_bb) - parseFloat(manual_data.fba_fees) - manual_data.buy_cost;
				}
			}
			if(manual_est_profit){
				manual_data['est_profit'] = manual_est_profit.toFixed(2)
			}
			manual_research_data.unshift(manual_data);
			return state
				.set("research_data", Object.assign([], manual_research_data))
				.set('is_loading_manual_search', false)
				.set('is_showing_selection_items', false)
				.set('search_results', [])

		case actions.RESEARCH_DATA_UPDATE_SUCCESS:
			const old_moveToBatchData = Object.assign([], state.get("moveToBatchData"));
			const update_moveToBatchData = old_moveToBatchData.map(item => {
				if(action.data.id === item.id){
					item[action.data.key] = action.data.val;
				}
				return item;
			});
			return state
				.set('moveToBatchData', update_moveToBatchData)

		case actions.RESEARCH_MOVE_TO_BATCH_DATA_SET_SUCCESS:
			const sold_moveToBatchData = Object.assign([], action.data);
			const supdate_moveToBatchData = sold_moveToBatchData.map(item => {
				item.price = item.price ? item.price : null;
				item.qty = item.qty ? item.qty : null;
				item.condition = item.condition ? item.condition : 'New';
				item.condition_move = selectOptions.find(x => x.value === item.condition);
				return item;
			});
			return state
				.set('moveToBatchData', supdate_moveToBatchData)


	default:
		return state;
	}
}
