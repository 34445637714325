import {
  all,
  takeLatest,
  put,
  fork,
  call
} from "redux-saga/effects";

import actions from './actions'

import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';

export function* userPageviewCapture() {
  yield takeLatest('APP_USER_PAGEVIEW_CAPTURE', function* (payload) {
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.user.app_event_pageview, payload.data);
      yield put(actions.userPageviewCaptureSuccess(response.data));
    } catch (error) {
      yield put(actions.userPageviewCaptureError("Error execution API call"));
    }
  })
}

export default function* appSagas() {
  yield all([
    fork(userPageviewCapture),
  ])
}
